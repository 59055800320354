import React from "react"
import Layout from "../components/layout.en"
import SEO from "../components/seo"
import SmallHeader from "../components/SmallHeader"
import { Container, Row, Col } from "reactstrap"
import { graphql, useStaticQuery } from "gatsby"

const PolitykaPrywatnosci = () => {
  const images = useStaticQuery(graphql`
    {
      cover: file(
        name: { eq: "general-page-background" }
        relativeDirectory: { eq: "headers" }
      ) {
        id
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <Layout>
        <SEO title="Obowiązek informacyjny" />

        <SmallHeader
          title="Obowiązek informacyjny"
          backgroundName={images.cover}
          hasLink={true}
        />

        <Container>
          <Row>
            <Col md="10" className="mr-auto ml-auto">
              <div className="section">
                <div className="text-justify">
                  <p className="pb-3">
                    Zgodnie z art. 13 ust. 1 i ust. 2 Rozporządzenia Parlamentu
                    Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016
                    r. informujemy, iż:
                  </p>
                  <p>
                    1. Administratorem Państwa danych osobowych jest Anna
                    Łapiezo, z siedzibą w Szczecinie, ul. Górska 1, 71-737, NIP:
                    599 291 66 72, e-mail: rezerwacje@morska5.pl
                  </p>
                  <p>
                    2. Zakres gromadzonych przez Administratora danych jest
                    ściśle ograniczony do danych niezbędnych do realizacji
                    usługi lub umowy, tj. imię i nazwisko, numer telefonu i
                    adres e-mail w celu umożliwienia kontaktu w sprawach
                    związanych z wykonaniem zleconej usługi lub umowy.
                  </p>
                  <p>
                    3. Państwa dane osobowe przetwarzane będą w celu realizacji
                    usługi, wykonania umowy lub podjęcia działań przed jej
                    zawarciem na podstawie art. 6 ust. 1 pkt. b, oraz w celach
                    marketingowych (tylko pod warunkiem wyrażenia przez Państwa
                    zgody na takie działania) na podstawie art. 6 ust. 1 lit. a.
                  </p>
                  <p>
                    4. Osoby, którym ujawnia się dane osobowe są upoważnionymi
                    pracownikami/ współpracownikami Administratora zobowiązanymi
                    do zachowania tajemnicy. Posiadają oni dostęp do danych
                    wyłącznie z uwagi na konieczność wykonywania obowiązków
                    związanych z realizacją usługi, przygotowaniem oferty lub
                    wykonaniem umowy.
                  </p>
                  <p>
                    5. W zależności od zleconej usługi odbiorcami Państwa danych
                    osobowych będą:
                  </p>
                  <p className="pl-4">
                    a) H88 S.A. z siedzibą w Poznaniu, Franklina Roosevelta 22,
                    60-829 Poznań, wpisana do Krajowego Rejestru Sądowego przez
                    Sąd Rejonowy Poznań – Nowe Miasto i Wilda w Poznaniu,
                    Wydział VIII Gospodarczy Krajowego Rejestru Sądowego pod nr
                    KRS 0000612359, REGON 364261632, NIP 7822622168 z tytułu
                    obsługi informatycznej świadczonej na potrzeby
                    Administratora
                  </p>
                  <p className="pl-4">
                    b) Paranormal Studio Agencja reklamowa z siedzibą w
                    Szczecinie 71-602, ul. Storrady Świętosławy 1/302 z tytułu
                    obsługi serwisu www
                  </p>
                  <p>6. Pani/Pana dane osobowe będą przechowywane:</p>
                  <p className="pl-4">
                    a) W przypadku zlecenia realizacji usługi – zgodnie z ustawą
                    o rachunkowości
                  </p>
                  <p className="pl-4">
                    b) W przypadku przygotowania oferty bądź udzielenia
                    odpowiedzi na zapytanie – przez 30 dni{" "}
                  </p>
                  <p className="pl-4">
                    c) W przypadku wykonania umowy – zgodnie z ustawą o
                    rachunkowości oraz tak długo jak będzie to niezbędne w celu
                    rozstrzygnięcia ewentualnych roszczeń związanych z
                    realizacją umowy
                  </p>
                  <p>
                    7. Mają Państwo prawo wniesienia skargi do GIODO gdy uzna
                    Pani/Pan, iż przetwarzanie danych osobowych Państwa
                    dotyczących narusza przepisy ogólnego rozporządzenia o
                    ochronie danych osobowych z dnia 27 kwietnia 2016 r.;
                  </p>
                  <p>
                    8. Podanie przez Państwa danych osobowych jest dobrowolne
                    ale jest warunkiem realizacji usługi, przygotowania oferty
                    bądź zawarcia umowy. W przypadku odmowy podania danych
                    osobowych Administrator nie będzie zdolny do wykonania
                    usługi, przedstawienia oferty bądź zawarcia umowy.
                  </p>
                  <p>
                    9. Państwa dane nie będą przetwarzane w sposób
                    zautomatyzowany w tym również w formie profilowania.
                  </p>
                  <p className="pl-4">
                    a) Cookies. Wszystkie niezbędne informacje o cookies znajdą
                    Państwo na stronie www.wszystkoociasteczkach.pl ). Poprzez
                    zmianę ustawień przeglądarki internetowej mogą Państwo w
                    każdym momencie dezaktywować zapisywanie plików cookies,
                    ograniczyć je do określonych stron www lub zażądać
                    powiadomień w przypadku wysyłania cookies.
                  </p>
                  <p className="pl-4">
                    b) AdWords. Reklamy spersonalizowane. Zasady AdWords:
                    www.support.google.com/adwordspolicy )
                  </p>
                  <p className="pl-4">
                    c) Google Analytics. Narzędzie do analizy zachowań
                    konsumenckich na stronach internetowych i tworzenia raportów
                    o tych zachowaniach. Informacje o prywatności:
                    https://policies.google.com/privacy/partners?hl=pl Google
                    Analytics można zablokować instalując dodatek do
                    przeglądarki znajdujący się pod tym adresem:
                    https://tools.google.com/dlpage/gaoptout Informujemy, że w
                    swojej działalności wykorzystujemy poniższe narzędzia
                    internetowe w celu analizy marketingowej oraz prezentacji
                    oferty.
                  </p>
                  <p className="pt-4">
                    Posiadają Państwo prawo dostępu do treści swoich danych oraz
                    prawo ich sprostowania, usunięcia, ograniczenia
                    przetwarzania, prawo do przenoszenia danych, prawo
                    wniesienia sprzeciwu, prawo do cofnięcia zgody w dowolnym
                    momencie bez wpływu na zgodność z prawem przetwarzania,
                    którego dokonano na podstawie zgody przed jej cofnięciem. W
                    celu realizacji powyższych praw prosimy o kontakt z
                    Administratorem (patrz pkt. 1). Administrator zastrzega
                    sobie możliwość weryfikacji tożsamości osoby zgłaszającej
                    powyższe żądania w celu zapobieżenia dostępowi osób
                    nieuprawnionych do Państwa danych.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default PolitykaPrywatnosci
